import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.project.info');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Location } from '@angular/common';

@Component({
    selector: 'wiz-component-project-info',
template: templateSource || '',
    styles: [``],
})
export class ComponentProjectInfoComponent implements OnInit {
    @Input() id: string = '';
    @Input() project: any;
    public data: any = {};
    public modal: any = { show: false, result: null };

    constructor(@Inject( Service) public service: Service,@Inject( Location)  private location: Location) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        await this.load();
        await this.service.render();
    }

    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load() {
        if (!this.id) this.id = WizRoute.segment.id;
        const { code, data } = await wiz.call("load", { id: this.id });
        if (code == 200) {
            this.project = data;
        } else {
            await this.alert("프로젝트 정보를 불러오는 데 실패했습니다.", "오류");
        }
        await this.service.render();
    }

    public async extract() {
        await this.service.loading.show();
        const { code, data } = await wiz.call("extract", { id: this.id });

        // let download = wiz.url("extract?id=" + this.id);
        // window.open(download, '_blank');
        this.modal = { show: true, result: data };
        await this.service.loading.hide();
    }

    public async extract_all() {
        await this.service.loading.show();
        // const { code, data } = await wiz.call("download_combined_excel", { id: this.id });

        let download = wiz.url("download_combined_excel?id=" + this.id);
        window.open(download, '_blank');
        // this.modal = { show: true, result: data };
        let images = wiz.url("images?id=" + this.id);
        window.open(images, '_blank');
        await this.service.loading.hide();
    }

    public goBack() {
        this.location.back();
    }
}

export default ComponentProjectInfoComponent;