import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.data.facility');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-data-facility',
template: templateSource || '',
    styles: [``],
})
export class ComponentAdminDataFacilityComponent implements OnInit {
    public id = "";
    public title = "";
    public contents = "";
    public list = [];

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        if (code === 200) {
            this.list = data.map(item => ({
                ...item,
                icon_uploaded: item.icon_uploaded || false, // icon_uploaded 상태를 반영
            }));
            await this.service.render();
        }
    }

    public async update(item) {
        if (!item) item = { id: this.id, title: this.title, contents: this.contents };
        const list = this.list.filter(facility => facility.id !== item.id);
        list.push({ id: item.id, title: item.title, contents: item.contents, count: 0 });
        const value = JSON.stringify({ data: list });
        const { code } = await wiz.call("update", { value });

        if (code === 200) {
            await this.alert("완료", "시설물 정보가 성공적으로 저장되었습니다.", "success");
            await this.load();
        } else {
            await this.alert("실패", "시설물 정보를 저장하는 중 문제가 발생했습니다.", "error");
        }
    }

    public async delete(item) {
        const res = await this.alert(
            "카테고리 삭제",
            "카테고리를 정말 삭제하시겠습니까?",
            "error",
            "취소",
            "삭제"
        );

        if (res) {
            const { code } = await wiz.call("delete", { id: item.id });

            if (code === 200) {
                await this.alert("삭제 완료", "카테고리가 성공적으로 삭제되었습니다.", "success");
                await this.load();
            } else {
                await this.alert("삭제 실패", "삭제하는 중 문제가 발생했습니다.", "error");
            }
        }
    }

    public async alert(title: string, message: string, status: string = "error", cancel: any = false, action: string = "확인") {
        return await this.service.alert.show({
            title,
            message,
            cancel,
            actionBtn: status,
            action,
            status
        });
    }

    public async upload(item) {
        let file = await this.service.file.select({ multiple: false });
        if (file.length === 0) return;
        let fd = new FormData();
        fd.append('file[]', file[0], item);
        let url = wiz.url("upload");
        let { code, data } = await this.service.file.upload(url, fd);
        if (code === 200) {
            this.list = this.list.map(facility => {
                if (facility.id === item) {
                    facility.icon_uploaded = true;
                }
                return facility;
            });
            await this.service.render();
            await this.alert("업로드 완료", "아이콘이 성공적으로 업로드되었습니다.", "success");
        } else {
            await this.alert("업로드 실패", "아이콘 업로드 중 문제가 발생했습니다.", "error");
        }
    }

}

export default ComponentAdminDataFacilityComponent;