import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.project.drawing');
import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import html2canvas from 'html2canvas';

@Component({
    selector: 'wiz-component-project-drawing',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.project.drawing/view.scss */
.active {
  filter: invert(33%) sepia(97%) saturate(2599%) hue-rotate(199deg) brightness(100%) contrast(105%);
}`],
})
export class ComponentProjectDrawingComponent implements OnInit, AfterViewInit {
    @Input() id: string = '';
    @ViewChild('drawflowContainer', { static: false }) drawflowContainer!: ElementRef;

    public backgroundUrl: string;
    public inspection: any = { type: "", size: 10, position: { x: null, y: null }, location: "", component: "", content: "", scaleW: "", scaleL: "", count: "", amount: "", damage: "", message: "", attachment: [] };
    public nodes = [];
    public damages = [];

    private isDragging = false;
    private isNodeDragging = false;
    private startX = 0;
    private startY = 0;
    private initialCanvasX = 0;
    private initialCanvasY = 0;
    private startNodeX = 0;
    private startNodeY = 0;
    private initialNodeX = 0;
    private initialNodeY = 0;
    public canvas_x = 0;
    public canvas_y = 0;
    public zoom = 1;
    public zoom_max = 5;
    public zoom_min = 1;
    public zoom_value = 0.5;
    public zoom_last_value = 1;

    public fd = new FormData();

    constructor(@Inject( Service) public service: Service) { }

    async ngOnInit() {
        await this.service.init();
        this.backgroundUrl = wiz.url("image?id=" + this.id);
        const { code, data } = await wiz.call("damages");
        this.damages = data;
        await this.load();
        this.nodes.forEach((el, i) => this.createNode(el));
        this.addCanvasEventListeners();
        await this.service.render();
    }

    public async load() {
        const { code, data } = await wiz.call("load", { id: this.id });
        this.nodes = data;
        await this.service.render();
    }

    addCanvasEventListeners() {
        const container = this.drawflowContainer.nativeElement;

        container.addEventListener('mousedown', (event) => {
            if (!this.isNodeDragging) this.startDrag(event);
        });
        container.addEventListener('mousemove', (event) => {
            if (!this.isNodeDragging) this.onDrag(event);
            else this.onNodeDrag(event);
        });
        container.addEventListener('mouseup', () => this.endDrag());

        container.addEventListener('touchstart', (event) => {
            if (!this.isNodeDragging) this.startDrag(event);
        });
        container.addEventListener('touchmove', (event) => {
            if (!this.isNodeDragging) this.onDrag(event);
            else this.onNodeDrag(event);
        });
        container.addEventListener('touchend', () => this.endDrag());
    }

    // Canvas drag functions
    startDrag(event: MouseEvent | TouchEvent) {
        this.inspection = { type: "", size: 10, position: { x: null, y: null }, location: "", component: "", content: "", scaleW: "", scaleL: "", count: "", amount: "", damage: "", message: "", attachment: [] };
        this.isDragging = true;
        const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        const clientY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
        this.startX = clientX;
        this.startY = clientY;
        this.initialCanvasX = this.canvas_x;
        this.initialCanvasY = this.canvas_y;
        event.preventDefault();
        // 모든 노드에서 filter 스타일 제거
        this.nodes.forEach(node => {
            const nodeElement = document.getElementById(`node-${node.id}`);
            if (nodeElement) {
                nodeElement.style.filter = ''; // 기존 filter 제거
            }
        });

    }

    onDrag(event: MouseEvent | TouchEvent) {
        if (!this.isDragging) return;
        const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        const clientY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
        const deltaX = clientX - this.startX;
        const deltaY = clientY - this.startY;
        this.canvas_x = this.initialCanvasX + deltaX;
        this.canvas_y = this.initialCanvasY + deltaY;
        this.updateTransform();
    }

    endDrag() {
        this.isDragging = false;
        if (this.isNodeDragging) this.endNodeDrag()
    }

    private async updateTransform() {
        this.drawflowContainer.nativeElement.style.transform = `translate(${this.canvas_x}px, ${this.canvas_y}px) scale(${this.zoom})`;
        await this.service.render();
    }

    zoom_refresh() {
        this.canvas_x = (this.canvas_x / this.zoom_last_value) * this.zoom;
        this.canvas_y = (this.canvas_y / this.zoom_last_value) * this.zoom;
        this.zoom_last_value = this.zoom;
        this.drawflowContainer.nativeElement.style.transform = `translate(${this.canvas_x}px, ${this.canvas_y}px) scale(${this.zoom})`;
        this.service.render();
    }

    zoom_in() {
        if (this.zoom < this.zoom_max) {
            this.zoom += this.zoom_value;
            this.zoom_refresh();
        }
    }

    zoom_out() {
        if (this.zoom > this.zoom_min) {
            this.zoom -= this.zoom_value;
            this.zoom_refresh();
        }
    }

    zoom_reset() {
        this.zoom = 1;
        this.init_pos();
    }

    init_pos() {
        this.canvas_x = 0;
        this.canvas_y = 0;
        this.updateTransform();
    }

    // Node drag functions
    startNodeDrag(event: MouseEvent | TouchEvent) {
        this.isNodeDragging = true;
        const target = event.target as HTMLElement;

        // 노드 ID 추출
        if (target && target.id && target.id.includes("node-")) {
            const nodeId = target.id.split('-')[1];
            const selectedNode = this.nodes.find(el => el.id === nodeId);

            if (selectedNode) {
                this.inspection = { ...selectedNode };

                // 모든 노드에서 filter 스타일 제거
                this.nodes.forEach(node => {
                    const nodeElement = document.getElementById(`node-${node.id}`);
                    if (nodeElement) {
                        nodeElement.style.filter = ''; // 기존 filter 제거
                    }
                });

                // 선택된 노드에 filter 스타일 적용
                const selectedNodeElement = document.getElementById(`node-${nodeId}`);
                if (selectedNodeElement) {
                    selectedNodeElement.style.filter = 'invert(33%) sepia(97%) saturate(2599%) hue-rotate(199deg) brightness(100%) contrast(105%)';
                }
            }
            this.service.render();
        }

        const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        const clientY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
        this.startNodeX = clientX;
        this.startNodeY = clientY;

        const nodeElement = this.getNodeElement();
        this.initialNodeX = parseInt(nodeElement.style.left, 10) || 0;
        this.initialNodeY = parseInt(nodeElement.style.top, 10) || 0;
        event.preventDefault();
    }

    onNodeDrag(event: MouseEvent | TouchEvent) {
        if (!this.isNodeDragging) return;
        const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        const clientY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
        const deltaX = clientX - this.startNodeX;
        const deltaY = clientY - this.startNodeY;
        const nodeElement = this.getNodeElement();
        nodeElement.style.left = `${this.initialNodeX + deltaX}px`;
        nodeElement.style.top = `${this.initialNodeY + deltaY}px`;
    }

    async endNodeDrag() {
        this.isNodeDragging = false;
        const nodeElement = this.getNodeElement();
        this.inspection.position.x = parseInt(nodeElement.style.left, 10) || 0;
        this.inspection.position.y = parseInt(nodeElement.style.top, 10) || 0;

        if (this.inspection.id) {
            try {
                let body = JSON.parse(JSON.stringify(this.inspection));
                delete body.id;
                const { code, data } = await wiz.call("update", { id: this.inspection.id, body: JSON.stringify(body) });
                if (code !== 200) throw new Error(data);
            } catch (error) {
                console.error("Failed to update node position:", error);
            }
        }
    }

    getNodeElement() {
        return document.getElementById(this.inspection.id ? `node-${this.inspection.id}` : "node");
    }

    createNode(node: any) {
        const nodeElement = document.createElement('div');
        nodeElement.id = node.id ? `node-${node.id}` : "node";
        nodeElement.style.position = 'absolute';
        nodeElement.style.width = `${node.size}px`;
        nodeElement.style.height = `${node.size}px`;
        nodeElement.style.left = `${node.position.x}px`;
        nodeElement.style.top = `${node.position.y}px`;
        nodeElement.style.backgroundSize = "contain";
        nodeElement.style.backgroundImage = `url(/assets/damages/${node.type}.png)`;
        this.drawflowContainer.nativeElement.appendChild(nodeElement);
        this.initializeNodeDragListeners(nodeElement);
    }

    initializeNodeDragListeners(nodeElement: HTMLElement) {
        nodeElement.addEventListener("mousedown", (event) => this.startNodeDrag(event));
        nodeElement.addEventListener("touchstart", (event) => this.startNodeDrag(event));
    }

    changeNodeType(node: any) {
        const nodeElement = this.getNodeElement();
        if (!nodeElement) {
            this.createNode(node);
        } else {
            nodeElement.style.backgroundImage = `url(/assets/damages/${node.type}.png)`;
        }
    }

    changeNodeSize(node: any) {
        const nodeElement = this.getNodeElement();
        if (nodeElement) {
            nodeElement.style.width = `${node.size}px`;
            nodeElement.style.height = `${node.size}px`;
        }
    }

    async send(item: any) {
        if (item) this.inspection = item;
        await this.service.loading.show();
        const inspectionId = this.inspection.id;
        delete this.inspection.id;

        const body = JSON.stringify(this.inspection);
        this.fd.append("body", body);
        this.fd.append("id", inspectionId || this.id);

        const call = inspectionId ? "update" : "send";
        const url = wiz.url(call);

        const { code, data } = await this.service.file.upload(url, this.fd);
        await this.service.loading.hide();

        if (code !== 200) {
            return await this.alert(data);
        }

        this.inspection = { type: "", size: 10, position: { x: null, y: null }, location: "", component: "", content: "", scaleW: "", scaleL: "", count: "", amount: "", damage: "", message: "", attachment: [] };
        this.fd = new FormData();
        await this.service.render();
        await this.load();
    }

    async upload() {
        const files = await this.service.file.select();
        if (files.length === 0) return;

        this.inspection.attachment = this.inspection.attachment || [];
        console.log(Array.from(files))
        Array.from(files).forEach((file, i) => {
            this.inspection.attachment[i] = file.name;
            this.fd.append('file[]', file);
        });

        await this.service.render();
    }

    async removeFile(file: string) {
        const res = await this.alert("파일을 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;

        this.inspection.attachment = this.inspection.attachment.filter((attachment: string) => attachment !== file);
        await this.service.render();
    }

    async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title,
            message,
            cancel,
            actionBtn: status,
            action,
            status
        });
    }
    exportAsImage() {
        const canvasElement = this.drawflowContainer.nativeElement;
        const scale = 3; // Higher scale for better resolution

        // Track positions to avoid overlap of sequence numbers
        const sequencePositions = [];

        // Arrays to store generated elements for later removal
        const generatedElements = [];

        this.nodes.forEach((node, index) => {
            // Calculate position for sequence number that avoids overlap
            let offsetX = 10;
            let offsetY = -10;

            // Adjust position to avoid overlap
            while (sequencePositions.some(pos => Math.abs(pos.x - (node.position.x + offsetX)) < 60 && Math.abs(pos.y - (node.position.y + offsetY)) < 60)) {
                offsetX += 20;
                offsetY += 20;
            }

            sequencePositions.push({ x: node.position.x + offsetX, y: node.position.y + offsetY });

            // Create the sequence number element
            const sequenceNumberElement = document.createElement('div');
            sequenceNumberElement.style.position = 'absolute';
            sequenceNumberElement.style.left = `${node.position.x + offsetX}px`;
            sequenceNumberElement.style.top = `${node.position.y + offsetY}px`;
            sequenceNumberElement.style.width = `12px`; // Slightly larger than the node
            sequenceNumberElement.style.height = `12px`;
            sequenceNumberElement.style.borderRadius = '50%';
            sequenceNumberElement.style.backgroundColor = 'white';
            sequenceNumberElement.style.border = '1px solid black';
            sequenceNumberElement.style.display = 'flex';
            sequenceNumberElement.style.justifyContent = 'center';
            sequenceNumberElement.style.lineHeight = 0;
            sequenceNumberElement.style.fontSize = `8px`; // Smaller font size for inner text to stay within the circle
            sequenceNumberElement.style.fontWeight = 'normal';
            sequenceNumberElement.style.color = 'black';
            sequenceNumberElement.innerText = (index + 1).toString();

            // Append sequence number element to the drawflow container and add to tracking array
            canvasElement.appendChild(sequenceNumberElement);
            generatedElements.push(sequenceNumberElement);

            // Create a line element to connect the number to the node
            const lineElement = document.createElement('div');
            lineElement.style.position = 'absolute';
            lineElement.style.backgroundColor = 'black';
            lineElement.style.width = '1px';

            const x1 = node.position.x + node.size / 2;
            const y1 = node.position.y + node.size / 2;
            const x2 = node.position.x + offsetX + 6;
            const y2 = node.position.y + offsetY + 6;

            const length = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
            lineElement.style.height = `${10}px`;

            // Calculate angle for the line
            const angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI) - 100;

            // Position and rotate the line element
            lineElement.style.left = `${x1}px`;
            lineElement.style.top = `${y1}px`;
            lineElement.style.transformOrigin = 'top left';
            lineElement.style.transform = `rotate(${angle}deg)`;

            // Append line element to the drawflow container and add to tracking array
            canvasElement.appendChild(lineElement);
            generatedElements.push(lineElement);
        });

        // Use html2canvas to export the image with sequence numbers and lines
        const options = {
            backgroundColor: '#ffffff',
            scale: scale,
            useCORS: true
        };

        html2canvas(canvasElement, options).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = imgData;
            link.download = 'drawflow-export.png';
            link.click();

            // Remove only the generated sequence numbers and lines after exporting
            generatedElements.forEach((el) => el.remove());
        });
    }
}

export default ComponentProjectDrawingComponent;