import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.facility.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-facility-list',
template: templateSource || '',
    styles: [``],
})
export class ComponentAdminFacilityListComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) {}

    public items: any = [];
    public loaded: boolean = false;

    public search: any = { page: 1 };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public status: any = false;

    public itemsPerPage: number = 20;

    public backgroundUrls: { [key: string]: string } = {};

    public editModal: any;
    public editEvent: any = {
        close: async () => {
            this.editModal = null;
            await this.load();
            await this.service.render();
        },
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(["admin", "manager"], "/");
        await this.load();

        this.items.forEach((item: any) => {
            this.backgroundUrls[item.type] = wiz.url(`icon?id=${item.type}`);
        });

        await this.service.render();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code !== 200) return;

        const { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;

        this.items = rows;

        this.items.forEach((item: any) => {
            this.backgroundUrls[item.type] = wiz.url(`icon?id=${item.type}`);
        });

        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public loadMore() {
        this.search.page++;
        this.load(this.search.page);
    }

    public async edit(id: string) {
        this.editModal = id;
        await this.service.render();
    }
}

export default ComponentAdminFacilityListComponent;