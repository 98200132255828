import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.facility.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-facility-list',
template: templateSource || '',
    styles: [``],
})
export class ComponentFacilityListComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public items: any = [];
    public loaded: boolean = false;

    public search: any = { page: 1, text: '', sort: 'created' };  // 정렬 옵션 추가

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public itemsPerPage: number = 20;

    public backgroundUrls: { [key: string]: string } = {};

    public async ngOnInit() {
        await this.service.init();
        await this.load();

        this.items.forEach((item: any) => {
            this.backgroundUrls[item.type] = wiz.url(`icon?id=${item.type}`);
        });

        await this.service.render();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        if (page === 1) {
            this.items = rows; // 첫 페이지일 경우 데이터 초기화
        } else {
            this.items = [...this.items, ...rows]; // 다른 페이지일 경우 기존 데이터에 추가
        }

        this.items.forEach((item: any) => {
            this.backgroundUrls[item.type] = wiz.url(`icon?id=${item.type}`);
        });
        
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public loadMore() {
        this.search.page++;
        this.load(this.search.page);
    }
}

export default ComponentFacilityListComponent;